<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1 v-if="$myCity.name">Новости {{$myCity.name}}</h1>
                <h1 v-else>Новости</h1>
                <router-link :to="{name: 'Offer'}" v-if="!$averageWidth()">
                    <div class="news_add_button_box_top">
                        <button class="news_add_button_top">Предложить новость</button>
                    </div>
                </router-link>
            </div>
            <!-- <NewsListLink /> -->
            <SearchPlagin cat_search="News" info="Поиск в новостях" />
            <div class="default_box_content" v-show="preLoad" itemscope itemtype="http://schema.org/Blog">
                <div itemprop="description" v-html="'Свежие новости города '+$myCity.name+' с фото и видео, события, происшествия, дтп. Свежие репортажи, актуальные комментарии читайте на сайте Окей Город '+$myCity.name+'.'" style="display: none"></div>
                <div v-for="(item, index) in list" :key="index">
                    <article class="default_item bg_white" itemprop="blogPosts" itemscope itemtype="http://schema.org/BlogPosting">
                        <div itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
                            <div itemprop="logo" itemscope itemtype="https://schema.org/ImageObject">
                                <img itemprop="url image" src="/images/logoHD.png" alt="Logo" title="Logo" style="display:none;" />
                            </div>
                            <meta itemprop="name" :content="'Окей Город - '+ $myCity.name">
                            <meta itemprop="telephone" content="">
                            <meta itemprop="address" content="Россия">
                        </div>
                        <time itemprop="datePublished" :datetime="$moment(item.created_at).local().format('YYYY-MM-DD')"></time>
                        <meta itemprop="dateModified" :content="$moment(item.created_at).local().format('YYYY-MM-DD')">
                        <meta itemscope itemprop="mainEntityOfPage" itemType="https://schema.org/WebPage" :itemid="$config.url + '/' + $myCity.name_en + '/news/' + item.id" />
                        <b-row no-gutters>
                            <b-col lg="4" class="default_item_image_box">
                                <link v-if="item.image" rel="prefetch prerender" :href="item.image" itemprop="image" />
                                <v-lazy-image :src="(item.image) ? item.image : '/images/default_image.jpg'" class="img-fluid" itemprop="image" />
                            </b-col>
                            <b-col lg="8" class="default_item_body">
                                <h2 class="default_item_title" v-if="item.title" itemprop="headline">
                                    <router-link :to="{name:'News',params: { id:item.id }}" class="link_news">
                                        {{item.title}}
                                    </router-link>
                                </h2>
                                <div class="default_item_text" v-html="$htmContext($cutText(item.text, limit))" itemprop="description"></div>
                                <div class="default_item_footer">
                                    <div class="default_item_footer_date" v-html="$moment(item.created_at).local().format('DD.MM.YYYY в HH:MM')"></div>
                                    <div class="default_item_footer_comment">
                                        <span v-if="parseInt(item.comments_count) > 0" class="default_item_footer_com_count">{{item.comments_count}}</span>
                                        <font-awesome-icon :icon="['fa', 'comment']" class="default_item_footer_date_icon" />
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </article>
                    <keep-alive>
                        <AdsPlaginNew v-if="index !== 0 && index % 3 === 0" :direction="'horizontal'" />
                    </keep-alive>
                </div>
                <div v-if="button_preloader">
                    <button v-if="page < totalPages" class="load_next_button" @click="api_next()">Показать ещё</button>
                </div>
                <div v-else>
                    <PreLoadBox />
                </div>
            </div>
            <div v-if="!preLoad">
                <PreLoadBox />
            </div>
        </div>
    </div>
</template>
<script>
// import NewsListLink from "./NewsListLink";
import SearchPlagin from '@/components/Plagins/SearchPlagin';
/* eslint-disable no-console */
export default {
    name: "NewsList",
    components: {
        // NewsListLink,
        SearchPlagin
    },
    metaInfo() {
        return {
            title: this.list && this.list.length ? `Новости ` : null,
            meta: [
                { name: 'keywords', itemprop: 'keywords', content: `${this.$myCity.name}, новости, сегодня, последние, официальные` },
                { name: 'description', itemprop: 'description', content: `Свежие новости города ${this.$myCity.name} с фото и видео, события, происшествия, дтп. Свежие репортажи, актуальные комментарии читайте на сайте Окей Город ${this.$myCity.name}.` },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: `Новости - ${this.$myCity.name}` },
                { property: 'og:description', content: `Свежие новости города ${this.$myCity.name} с фото и видео, события, происшествия, дтп. Свежие репортажи, актуальные комментарии читайте на сайте Окей Город ${this.$myCity.name}.` },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            page: 1,
            list: [],
            totalPages: null,
            limit: 120,
            cat_search: 'News',
            button_preloader: 'finish',

        }
    },
    methods: {
        api: function() {
            let url = this.$config.api_url + this.$route.params.city + '/News.getList';
            this.$http.get(url, {
                params: {
                    page: this.page,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.response.top).concat(response.data.response.list);
                this.totalPages = response.data.response.link.total_pages;
            }).finally(() => {
                this.preLoad = 'finish';

            })
        },
        api_next() {
            this.button_preloader = null;
            let url = this.$config.api_url + this.$route.params.city + '/News.getList';
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.response.list);
            }).finally(() => {
                this.button_preloader = 'finish';

            })
        },
        cutText(value, limit) {
            return value.length > limit ?
                value.slice(0, limit - 3) + '...' :
                value;
        }

    },
    mounted() {
        this.$scrollToTop();
        this.api();
    },
}
</script>
<style scoped>
@media only screen and (max-width: 991px) {
    .default_header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (min-width: 992px) {
    .default_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

}

h2 {
    font-weight: bold !important;
}

.news_add_button_box_top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.news_add_button_top {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    border-radius: 25px;
    background: #4a76a8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    outline: none;
    border: none;
}

.news_add_button_top:focus {
    outline: none;
    border: none;
}

.news_add_button_top:hover {
    background: #333333;
}

.link_news:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
}

.row {
    position: relative;
    z-index: 1;
}

.default_item_body  {
    position: static;
}

.default_item_image_box {
    z-index: -1;
}

.default_item_image_box img {
    z-index: -1;
}
</style>